<template>
	<div class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>
<!-- 		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/> -->
				 <Load v-if="isLoading"/>

		
		<div v-if="museum" class="container">
			<img class="bg-image" :src="museum.homeImageUrl" alt="">
		</div>
		
		<main v-if="museum">
			<router-link :to="{ name: 'Presentation', params: { idMuseum: encrypted } }" class="logo">
				<img class="logo-image" :src="museum.logoImageUrl" :alt="museum.locale ? museum.locale.name : ''">
			</router-link>
			
			<h1 class="title">{{ $t('download_resources') }}</h1>
			<!-- Shown when there is no data in iDB about the museum -->
			<p v-if="shouldDoFullDownload" class="question">{{ $t('offline_content_description') }}</p>
			
			<!-- Shown when there is data in iDB about the museum but not from some other resources -->
			<p v-if="shouldDoPartialDownload" class="question">{{ $t('some_resources_not_downloaded') }}</p>
			<ul v-if="shouldDoPartialDownload" class="question">
				<li v-for="resource in resourcesOutOfDB" :key="resource">{{ $t(resource) | capitalize }}</li>
			</ul>
			
			<!-- Shown when everything is stored in the iDB -->
			<p v-if="downloadNotNeeded" class="question">{{ $t('previously_downloaded_ok') }}</p>
			
			<!-- Shown when there was an error with the download -->
			<p v-if="error" class="question">{{ $t('error_download') }}</p>
			
			<!-- Shown when it is downloading the resources -->
			<p v-if="!error && downloading" class="question">{{ $t('downloading_resources') }}</p>
			<p v-if="!error && downloading" class="saving-resource">{{ savingResource }}</p>
			<div v-if="downloading">
				<progress :value="savedResources" :max="totalResources"> {{ ((savedResources * 100) / totalResources) }} </progress>
			</div>
			
			
		</main>
		
		<footer v-if="!downloading">
			<div v-if="shouldDoFullDownload || shouldDoPartialDownload" class="footer-buttons">
				<button class="button-white" @click="goToOptionsWithOnlineDownload()">
					{{ $t('no') }}
				</button>
				<button class="button-blue" @click="startDownloading()">
					{{ $t('yes') }}
				</button>
			</div>
			<div v-else class="footer-buttons">
				<button class="button-blue" @click="goToOptions()">
					<i class="mm-icon mm-icon-arrow-right white big"/>
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
// import Loading from "vue-loading-overlay";
import {setFullScreen} from "@/store/helpers";
import {museumResources} from "@/store/helpers";
import Load from '../../components/Load.vue';

export default {
	name: "Download",
	
	components: {
		Load,
		// Loading
	},
	
	filters: {
		capitalize: function (value) {
			if (!value) return '';
			value = value.toString();
			return value.charAt(0).toUpperCase() + value.slice(1);
		}
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			downloading: false,
			error: false,
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			savedResources: state => state.museum.savedResources,
			savingResource: state => state.museum.savingResource,
			totalResources: state => state.museum.totalResources,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			museumError: state => state.app.museumFetchingError,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('download_resources');
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		resourcesOutOfDB() {
			let resources = museumResources.filter(resource => this.museum.resourcesFromDB.indexOf(resource) === -1);
			this.$store.commit('app/' + mutationTypes.SET_RESOURCES_OUT_OF_DB, resources);
			return resources;
		},
		
		shouldDoFullDownload() {
			return !this.error && !this.downloading && !this.museum?.fromDB;
		},
		
		shouldDoPartialDownload() {
			return !this.error && !this.downloading && this.museum?.fromDB
				&& this.resourcesOutOfDB.length && (this.resourcesOutOfDB.length !== this.museum?.resourcesFromDB?.length);
		},
		
		downloadNotNeeded() {
			return !this.error && !this.downloading && this.museum.fromDB
				&& !this.resourcesOutOfDB.length;
		},
		
		cssVars() {
			return {
				'--main-buttons-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsColor : '#72c4d0',
				'--main-buttons-text-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsTextColor : '#ffffff',
			};
		}
	},
	
	created () {
		debug.log("[Download] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseumPresentation(this.idMuseum)
				.finally(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseumPresentation']),
		...mapActions('museum', ['fetchMuseumDetail']),
		
		goToOptions() {
			localStorage.setItem('download-resources', 'already-downloaded');
			this.$router.push({ name: 'Options', params: { idMuseum: this.encrypted } });
		},
		
		goToOptionsWithOnlineDownload() {
			setFullScreen();
			this.error = false;
			
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
			
			try {
				let decryption = (atob(this.encrypted));
				this.idMuseum = decryption.split('-')[0];
				
				this.fetchMuseumDetail(this.idMuseum)
					.then(() => {
						localStorage.setItem('download-resources', 'no');
						this.$router.push({ name: 'Options', params: { idMuseum: this.encrypted } });
					})
					.catch(() => {
						this.error = true;
						this.downloading = false;
					})
					.finally(() => {
						this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
					});
			} catch(e) {
				if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
				this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
			}
		},
		
		startDownloading() {
			setFullScreen();
			this.downloading = true;
			this.error = false;
			
			this.$store.commit('app/' + mutationTypes.SET_IS_NETWORK_ONLINE, false);
			
			this.fetchMuseumDetail(this.idMuseum)
				.then(() => {
					localStorage.setItem('download-resources', 'yes');
					this.$router.push({ name: 'Options', params: { idMuseum: this.encrypted } });
				})
				.catch(() => {
					this.error = true;
					this.downloading = false;
				})
				.finally(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		},
	}
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	height: 100%;
	
	.container {
		overflow: hidden;
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: -1;
		
		.bg-image {
			width: 100%;
			height: 100%;
			filter: blur(20px);
			transform: scale(1.5);
		}
	}
	
	main {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		
		.qr-overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			overflow: hidden;
			margin: 0 auto;
		}
		
		.logo {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			min-height: 35%;
			padding-top: 75px;
			
			.logo-image {
				width: 70%;
				height: auto;
			}
		}
		
		h1 {
			margin-top: 20px;
			color: white;
			font-weight: normal;
			font-size: 24px;
			position: relative;
			
			&::after {
				content: "";
				position: absolute;
				left: -15%;
				top: 50px;
				width: 130%;
				height: 1px;
				background-color: white;
			}
		}
		
		.question {
			margin-top: 50px;
			margin-bottom: 10px;
			text-align: center;
			color: white;
			padding: 0 20px;
		}
		
		.saving-resource {
			color: white;
			margin-bottom: 20px;
			font-size: 14px;
		}
		
		.need-download {
			text-align: left;
		}
		
		progress[value] {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			
			border: none;
			
			/* For IE10 */
			color: var(--main-buttons-color);
			
			width: 300px;
			height: 20px;
			background-color: var(--main-buttons-text-color);
			
			&::-webkit-progress-bar {
				background-color: var(--main-buttons-text-color);
				border-radius: 2px;
				//box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
			}
			
			&::-webkit-progress-value {
				background-color: var(--main-buttons-color);
				border-radius: 2px;
				
				-webkit-animation: animate-stripes 5s linear infinite;
				animation: animate-stripes 5s linear infinite;
			}
			
			@-webkit-keyframes animate-stripes {
				100% { background-position: -100px 0px; }
			}
			
			@keyframes animate-stripes {
				100% { background-position: -100px 0px; }
			}
		}
	}
	
	footer {
		position: fixed;
		bottom: 0;
		height: 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.footer-buttons {
			width: 100%;
			min-height: 90px;
			display: flex;
			justify-content: center;
			position: absolute;
			top: 0;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				font-size: 24px;
				display: flex;
				justify-content: center;
				align-items: center;
				
				&.button-white {
					background-color: white;
					color: black;
				}
				
				&.button-blue {
					background-color: var(--main-buttons-color);
					color: var(--main-buttons-text-color);
					
					.mm-icon {
					    color: var(--main-buttons-text-color);
					}
				}
			}
		}
	}
}
</style>
