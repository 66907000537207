<template>
	<div class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>
		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		<!-- <Load v-if="isLoading"></Load> -->
		<div v-if="museum" class="container">
			<img class="bg-image" :src="museum.homeImageUrl" alt="">
		</div>
		
		<main v-if="!isLoading && museum && !searchingQR">
			<router-link :to="{ name: 'Presentation', params: { idMuseum: encrypted } }" class="logo">
				<img class="logo-image" :src="museum.logoImageUrl" :alt="museum.locale ? museum.locale.name : ''">
			</router-link>
			
			<h1>{{ $t('insert_code') }}</h1>
			
			<transition name="appear">
				<p v-show="errorCode" class="error">{{ $t(errorCode) }}</p>
			</transition>
			
			<div class="input">
				<input ref="input" v-model="museumCode" type="text" :placeholder="$t('insert_code')" @keyup.enter="enterCode()">
				<button v-if="museum && museum.accessCodeQrEnabled" class="button-qr" @click="searchingQR = true">
					<i class="mm-icon mm-icon-qr-scanner"/>
				</button>
			</div>
		</main>
		
		<main v-if="!isLoading && museum && searchingQR">
			<transition name="appear">
				<p v-show="errorCode" class="error-camera">{{ $t(errorCode) }}</p>
			</transition>
			<qrcode-stream @decode="onDecode" @init="onInit" />
			<lottie
				class="qr-overlay"
				:options="qrAnimationOptions"
			/>
		</main>
		
		<footer>
			<div class="footer-buttons">
				<button v-if="!searchingQR" class="button-blue" @click="enterCode()">
					<i class="mm-icon mm-icon-arrow-right white big"/>
				</button>
				<button v-else class="button-white" @click="searchingQR = false">
					<i class="mm-icon mm-icon-close big"/>
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
import MuseumsAPI from "@/api/MuseumsAPI";
import Loading from "vue-loading-overlay";
import { QrcodeStream } from 'vue-qrcode-reader';
const Lottie = () => import("vue-lottie");
import * as animationData from '@/assets/animations/camera.json';
// import Load from '../../components/Load.vue';

export default {
	name: "Code",
	
	components: {
		// Load,
		Loading,
		QrcodeStream,
		Lottie
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			errorCode: null,
			searchingQR: false,
			museumCode: this.$route.query?.code || "",
			qrAnimationOptions: {
				animationData: animationData.default,
				animationSpeed: 1
			},
			codeQueryParam: this.$route.query.code
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			savingResource: state => state.museum.savingResource,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			museumError: state => state.app.museumFetchingError,
			code: state => state.app.code
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('insert_code');
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		cssVars() {
			return {
				'--main-buttons-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsColor : '#72c4d0',
				'--main-buttons-text-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsTextColor : '#ffffff',
			};
		}
	},
	
	created () {
		debug.log("[Code] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			this.loadMuseumPresentation(this.idMuseum)
				.finally(() => {
					
					if (this.codeQueryParam) {
						this.museumCode = this.codeQueryParam;
						this.enterCode();
					} else {
						this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
					}
				});

		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseumPresentation']),
		
		enterCode() {
			debug.open("enterCode");
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
			
			if (this.museumCode) {
				if(this.museumCode === process.env.VUE_APP_BACKDOOR_CODE) {
					debug.log("[enterCode] Code " + this.museumCode + " is correct");
					this.$store.commit('app/' + mutationTypes.SET_CODE, this.museumCode);
					localStorage.setItem('code', this.museumCode);
					localStorage.setItem('code-creation-date', new Date());
					debug.log("[enterCode] Code " + this.museumCode + " saved correctly");
					this.$router.push({name: 'Languages', params: { idMuseum: this.encrypted} });
				} else {
          if (!this.isNetworkOnline){
            return;
          }
					return MuseumsAPI.checkAccessCode(this.idMuseum, this.museumCode)
						.then((result) => {
							if (result.data?.success) {
								debug.log("[enterCode] Code " + this.museumCode + " is correct");
								this.$store.commit('app/' + mutationTypes.SET_CODE, this.museumCode);
								localStorage.setItem('code', this.museumCode);
								localStorage.setItem('code-creation-date', JSON.stringify(new Date()));
								localStorage.setItem('code-days-until-expiration', result.data);
								debug.log("[enterCode] Code " + this.museumCode + " saved correctly");
								this.$router.push({name: 'Languages', params: { idMuseum: this.encrypted} });
							} else {
								debug.log("[enterCode] Code " + this.museumCode + " is not valid");
								this.errorCode = 'wrong_code';
								this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
							}
						})
						
						.catch((error) => {
							console.error(error);
							this.errorCode = 'wrong_code';
						})
						
						.finally(() => {
							if(!this.codeQueryParam){
								this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
							}
						});
				}
			} else {
				this.errorCode = 'empty_code';
			}
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
			
			debug.close();
		},
		
		onDecode (result) {
			this.museumCode = result;
			this.searchingQR = false;
		},
		
		async onInit (promise) {
			this.errorCode = null;
			try {
				await promise;
			} catch (error) {
				this.errorCode = 'qr_error_' + error.name;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	height: calc(100% - 90px);
	overflow-y: auto;
	
	.container {
		overflow: hidden;
		position: fixed;
		width: 100%;
		height: calc(100% - 90px);
		z-index: -1;
		
		.bg-image {
			width: 100%;
			height: 100%;
			filter: blur(20px);
			transform: scale(1.5);
		}
	}
	
	main {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		
		.qr-overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			overflow: hidden;
			margin: 0 auto;
		}
		
		.logo {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			margin: 20px 0;
			
			.logo-image {
				width: 70%;
				height: auto;
			}
		}
		
		h1 {
			color: white;
			font-weight: normal;
			font-size: 24px;
			position: relative;
			margin: 20px 0 40px;
			
			&::after {
				content: "";
				position: absolute;
				left: -15%;
				bottom: -10px;
				width: 130%;
				height: 1px;
				background-color: white;
			}
		}
		
		.appear-leave-active,
		.appear-enter-active {
			transition: 1s;
		}
		.appear-enter {
			opacity: 0;
			transform: translate(0, -100%);
		}
		.appear-leave-to {
			opacity: 0;
			transform: translate(0, 100%);
		}
		
		.error {
			margin-bottom: 30px;
			padding: 10px;
			background-color: $red;
			color: white;
			border-radius: 5px;
		}
		
		.error-camera {
			position: fixed;
			margin: 20px;
			padding: 10px;
			background-color: $red;
			color: white;
			border-radius: 5px;
		}
		
		.input {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 60px;
			
			input {
				height: 60px;
				padding: 20px;
				border: none;
				background-color: rgba(255, 255, 255, 0.4);
			}
			
			.button-qr {
				background-color: white;
				height: 100%;
				width: 60px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: none;
				
				i {
					font-size: 40px;
					margin-bottom: -8px;
				}
			}
		}
	}
	
	footer {
		position: fixed;
		bottom: 0;
		height: 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.footer-buttons {
			width: 100%;
			min-height: 90px;
			display: flex;
			justify-content: center;
			position: absolute;
			top: 0;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				display: flex;
				justify-content: center;
				align-items: center;
				
				&.button-white {
					background-color: white;
					color: black;
				}
				
				&.button-blue {
					background-color: var(--main-buttons-color);
					color: var(--main-buttons-text-color);
					
					.mm-icon {
						color: var(--main-buttons-text-color);
					}
				}
			}
		}
	}
}
</style>
