<template>
	<div class="content">
		<vue-headful
			:title="pageTitle"
		/>
        <!-- <Load v-if="isLoading"></Load> -->
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>

		<div v-if="museum" class="container">
			<img class="bg-image" :src="museum.homeImageUrl" alt="">
		</div>

		<main v-if="museum">
			<router-link :to="{ name: 'Presentation', params: { idMuseum: encrypted } }" class="logo">
				<img class="logo-image" :src="museum.logoImageUrl" alt="">
			</router-link>

			<h1>{{ $t('sign_in') }}</h1>

			<transition name="slide">
				<p v-show="loginError" class="error">{{ $t('user_name_error') }}</p>
			</transition>

			<div class="login-buttons">
				<button ref="facebookSignInButton" class="facebook-sign-in-button" @click="facebookLogin()">
					<img class="social-icon" src="@/assets/icons/facebook.png" alt="">
					{{ $t('login_facebook') }}
				</button>

				<div class="button-text-muted">
					<button ref="googleSignInButton" class="google-sign-in-button" @click="googleLogin()">
						<img class="social-icon" src="@/assets/icons/google.png" alt="">
						{{ $t('login_google') }}
					</button>
					<span>∴ Recuerde conceder permisos solicitados por Google.</span>
				</div>


				<!--				<button ref="instagramSignInButton" class="instagram-sign-in-button" @click="instagramLogin()">-->
				<!--					<img class="social-icon" src="@/assets/icons/instagram.png" alt="">-->
				<!--					{{ $t('login_instagram') }}-->
				<!--				</button>-->

				<button ref="twitterSignInButton" class="twitter-sign-in-button" @click="twitterLogin()">
					<img class="social-icon" src="@/assets/icons/twitter.png" alt="">
					{{ $t('login_twitter') }}
				</button>
			</div>

		</main>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
import Loading from "vue-loading-overlay";
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from "axios";
import UsersAPI from "@/api/UsersAPI";
// import Load from '../../components/Load.vue';

export default {
	name: "SocialLogin",

	components: {
		// Load,
		Loading,
	},

	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			loginError: false,
		};
	},

	computed: {
		...mapState({
			museum: state => state.museum.museum,
			savingResource: state => state.museum.savingResource,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			museumError: state => state.app.museumFetchingError,
			user: state => state.app.user,
		}),

		pageTitle() {
			let museumName = (this.museum && this.museum.name)
				? this.museum.name
				: (this.museum && this.museum.locale && this.museum.locale.name)
					? this.museum.locale.name
					: "MuseumMate";

			return this.$t('sign_in') + ' | ' + museumName;
		}
	},

	created () {
		debug.log("[SocialLogin] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);

		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];

			this.loadMuseumPresentation(this.idMuseum)
				.finally(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},

	methods: {
		...mapActions('app', ['loadMuseumPresentation', 'saveUserToDB']),

		facebookLogin() {
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
			this.loginError = false;
			const provider = new firebase.auth.FacebookAuthProvider();

			firebase.auth().signInWithPopup(provider)
				.then((result) => {
					if(result?.additionalUserInfo?.profile?.email) {
						let gender = (result?.additionalUserInfo?.profile?.gender === 'male' || result?.additionalUserInfo?.profile?.gender === 'female')
							? result.additionalUserInfo.profile.gender
							: null;
						let profile = {
							idMuseum: this.idMuseum,
							name: result?.additionalUserInfo?.profile?.name,
							email: result?.additionalUserInfo?.profile?.email,
							gender: gender,
							birthdate: new Date(result?.additionalUserInfo?.profile?.birthday),
							token: String(result?.additionalUserInfo?.profile?.id),
							imageUrl: result?.additionalUserInfo?.profile?.picture?.data?.url,
							method: 'facebook',
						};
						this.saveUser(profile);

					} else {
						this.loginError = true;
					}
				})
				.catch((error) => {
					console.error(error);
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		},

		googleLogin() {
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
			this.loginError = false;
			const provider = new firebase.auth.GoogleAuthProvider();
			//For the possible scopes check https://developers.google.com/identity/protocols/oauth2/scopes?authuser=5
			provider.addScope('https://www.googleapis.com/auth/user.gender.read');
			provider.addScope('https://www.googleapis.com/auth/user.birthday.read');
			provider.addScope('https://www.googleapis.com/auth/userinfo.email');

			firebase.auth().signInWithPopup(provider)
				.then((result) => {
					// For the possible personFields check https://developers.google.com/people/api/rest/v1/people/get
					let url = 'https://people.googleapis.com/v1/people/' + result.additionalUserInfo.profile.id
						+ '?personFields=birthdays,genders,emailAddresses'
						+ '&key=' + process.env.VUE_APP_FIREBASE_API_KEY
						+ '&access_token=' + result.credential.accessToken;

					axios.get(url)
						.then((response) => {
							if(response.data && response.data.hasOwnProperty('emailAddresses')) {
								let birthday = null;
								if(response.data.hasOwnProperty('birthdays') && response.data.birthdays[0] && response.data.birthdays[0].date) {
									birthday = response.data.birthdays[0].date;
									birthday = new Date(birthday.year ? birthday.year : '', birthday.month ? birthday.month - 1 : '', birthday.day ? birthday.day : '');
								}

								let genderValue = (response?.data?.genders && response.data.genders[0]?.value) ? response.data.genders[0].value : null;
								let gender = (genderValue === 'male' || genderValue === 'female')
									? result.additionalUserInfo.profile.gender
									: null;

								let profile = {
									idMuseum: this.idMuseum,
									name: result?.additionalUserInfo?.profile?.name,
									email: response?.data?.emailAddresses[0]?.value,
									gender: gender,
									birthdate: birthday,
									token: String(result?.additionalUserInfo?.profile?.id),
									imageUrl: result?.additionalUserInfo?.profile?.picture,
									method: 'google',
								};
								this.saveUser(profile);

							} else {
								this.loginError = true;
							}
						})
						.catch((error) => {
							console.error(error);
						});
				})
				.catch((error) => {
					console.error(error);
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		},

		instagramLogin() {
			this.loginError = true;
		},

		twitterLogin() {
			this.loginError = false;
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
			const provider = new firebase.auth.TwitterAuthProvider();

			firebase.auth().signInWithPopup(provider)
				.then((result) => {
					if(result?.additionalUserInfo?.profile?.email) {
						let gender = (result?.additionalUserInfo?.profile?.gender === 'male' || result?.additionalUserInfo?.profile?.gender === 'female')
							? result.additionalUserInfo.profile.gender
							: null;
						let profile = {
							idMuseum: this.idMuseum,
							name: result?.additionalUserInfo?.profile?.name,
							email: result?.additionalUserInfo?.profile?.email,
							gender: gender,
							birthdate: result?.additionalUserInfo?.profile?.birthday,
							token: String(result?.additionalUserInfo?.profile?.id),
							imageUrl: result?.additionalUserInfo?.profile?.profile_image_url,
							method: 'twitter',
						};
						this.saveUser(profile);

					} else {
						this.loginError = true;
					}
				})
				.catch((error) => {
					console.error(error);
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		},

		saveUser(profile) {
			debug.log("[saveUser] Signed in as", profile);
			UsersAPI.createUser(profile)
				.then((result) => {
					if(result.data.success) {
						localStorage.setItem('user', JSON.stringify(profile));
						this.$store.commit('app/' + mutationTypes.SET_USER, profile);
						this.goToNextView();
					} else {
						this.loginError = true;
					}
				})
				.catch((error) => {
					debug.log(error);
					this.loginError = true;
				});
		},

		goToNextView() {
			let name = 'Languages';

			//Checks for initial study
			if(this.museum.studies?.length) {
				debug.open("Checking study questions at the start of the visit");
				const hasStudy = this.museum.studies.some((study) => {
					if (['EASYGUIDE', 'BOTH'].includes(study.showOn)) {
						return study.questions.find(q => !q.idArtwork) != null;
					}

					return false;
				});
				if(hasStudy) {
					name = 'StudyQuestion';
				}
				debug.close();

			} else {
				if(!this.museum?.slides?.length) {
					name = 'Slides';
					localStorage.setItem('slides-seen', 'true');
				}
			}

			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
			this.$router.push({ name: name, params: { idMuseum: this.encrypted } });
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	height: 100%;

	.container {
		overflow: hidden;
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: -1;

		.bg-image {
			width: 100%;
			height: 100%;
			filter: blur(20px);
			transform: scale(1.5);
		}
	}

	main {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.logo {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			min-height: 35%;
			padding-top: 75px;

			.logo-image {
				width: 70%;
				height: auto;
			}
		}

		h1 {
			margin-top: 20px;
			color: white;
			font-weight: normal;
			font-size: 24px;
			position: relative;

			&::after {
				content: "";
				position: absolute;
				margin-left: -150%;
				margin-top: 50px;
				width: 200%;
				height: 1px;
				background-color: white;
			}
		}

		.slide-leave-active,
		.slide-enter-active {
			transition: 1s;
		}
		.slide-enter {
			opacity: 0;
			transform: translate(0, -100%);
		}
		.slide-leave-to {
			opacity: 0;
			transform: translate(0, 100%);
		}

		.error {
			margin: 60px 0 -60px;
			padding: 10px;
			background-color: $red;
			color: white;
			border-radius: 5px;
		}

		.login-buttons {
			flex: 2;
			height: 100%;
			width: 300px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			button {
				margin-bottom: 20px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				min-width: 100%;
				width: 100%;
				border: 1px solid hsla(0,0%,100%,.05);
				border-radius: 2px;
				padding: 10px 30px;

				.social-icon {
					width: 20px;
					height: auto;
					margin-right: 1.25ch;
				}
			}

			.facebook-sign-in-button {
				background-color: #026af6;
				color: white;
			}

			.google-sign-in-button {
				background-color: white;
				color: $gray-light;
			}

			.instagram-sign-in-button {
				background: #f09433;
				background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
				background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
				background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
				color: white;
			}

			.twitter-sign-in-button {
				background-color: #51a2df;
				color: white;
			}
		}
	}

	.button-text-muted button {
	margin-bottom: 0 !important;
	}

	.button-text-muted,.button-text-muted span {
	width: 100%;
	margin-bottom: 20px;
	font-size: 11px;
	color: white;
	}
}
</style>
